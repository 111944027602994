/**
 * Override Bulma derived variables
 */
$primary: #2e93d7;
$secondary: #ff9a00;
$danger: #d9534f;

$white: #fff;
$hover: #e7e7e7;
$gray-light: #cdd1d4;
$gray: #adb2b3;
$gray-dark: #777a7a;
$black: #313132;
$blueblack: #112;

$box-shadow: 0px 5px 12px -6px rgba(0, 0, 0, 0.05);
$family-sans-serif: 'Ubuntu';

$primary-disabled: #78afd4;
$primary-hover: #3e84b3;

$danger-disabled: #b16260;
$danger-hover: #a7423e;

$margin-1: 15px;
$margin-2: 30px;
$margin-3: 48px;

$border-radius: 3px;
