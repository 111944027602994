@import "./vars.scss";

@import "@angular/cdk/overlay-prebuilt.css";
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

/**
 * DOM styles
 */
html,
body {
  height: 100%;
}

body,
p {
  margin: 0;
}

.has-newlines {
  white-space: pre-wrap;
}

.procedure-group {
  font-weight: 500;
  margin-bottom: 12px;
  margin-top: 12px;

  span {
    font-weight: initial;
  }

  &:not(:first-of-type) {
    padding-top: 12px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $gray-light;
    padding-bottom: 12px;
  }
}

.hint {
  color: $gray-dark;
}

.action-buttons {
  @media (max-width: 600px) {
    button,
    a {
      margin-bottom: 12px;
      width: 100%;
    }
  }
}

.w-100 {
  width: 100%;
}

.mb-1 {
  margin-bottom: $margin-1;
}

.mb-2 {
  margin-bottom: $margin-2;
}

.mb-3 {
  margin-bottom: $margin-3;
}

.my-0 {
  margin-bottom: 0;
  margin-top: 0;
}

.my-1 {
  margin-bottom: $margin-1;
  margin-top: $margin-1;
}

.my-2 {
  margin-bottom: $margin-2;
  margin-top: $margin-2;
}

.my-3 {
  margin-bottom: $margin-3;
  margin-top: $margin-3;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.justify-content-between {
  justify-content: space-between;
}

.has-linebreaks {
  white-space: pre-line;
  word-break: break-word;
}

.has-text-primary {
  color: $primary;
}

.has-text-secondary {
  color: $secondary;
}

.has-background-primary {
  background-color: $primary;
}

.has-background-secondary {
  background-color: $secondary;
}

.has-font-weight-bold {
  font-weight: 700;
}

@import "node_modules/bulma/sass/utilities/_all";
@import "node_modules/bulma/sass/base/_all";

@import "node_modules/bulma/sass/components/dropdown";
@import "node_modules/bulma/sass/components/menu";
@import "node_modules/bulma/sass/components/navbar";
@import "node_modules/bulma/sass/components/tabs";

@import "node_modules/bulma/sass/elements/box";
@import "node_modules/bulma/sass/elements/button";
@import "node_modules/bulma/sass/elements/container";
@import "node_modules/bulma/sass/elements/content";
@import "node_modules/bulma/sass/form/_all";
@import "node_modules/bulma/sass/elements/tag";
@import "node_modules/bulma/sass/elements/table";
@import "node_modules/bulma/sass/elements/title";

@import "node_modules/bulma/sass/form/shared";
@import "node_modules/bulma/sass/form/tools";
@import "node_modules/bulma/sass/form/input-textarea";
@import "node_modules/bulma/sass/form/select";

@import "node_modules/bulma/sass/grid/columns";

@import "node_modules/bulma/sass/layout/footer";
@import "node_modules/bulma/sass/layout/section";

/**
 * Angular Material
 */
 .mat-mdc-list {
  .mat-mdc-list-item {
    &:hover {
      cursor:  pointer !important;
    }
  }
}

th.mat-mdc-header-cell,
td.mat-mdc-cell {
  vertical-align: middle !important;
}

@import "./ui-kit.scss";

.mat-mdc-dialog-container {
  background-color: $white;
}

table {
  border-color: $gray-light;
  border-radius: $border-radius;
}

// Mimics `uiKitInput` styles
.autocomplete-container {
  border: 1px solid $gray-light;
  border-radius: $border-radius !important;
  box-shadow: unset !important;
  color: $gray-dark !important;
  height: 42px !important;

  input {
    color: #777a7a !important;
    font-size: 16px !important;
    font-size: 1rem !important;
    height: 2.5em !important;
    padding-bottom: calc(0.5em - 1px) !important;
    padding-left: calc(0.75em - 1px) !important;
    padding-right: calc(0.75em - 1px) !important;
    padding-top: calc(0.5em - 1px) !important;
  }
}

.mat-mdc-list-base .mat-mdc-list-item,
.mat-list-base .mat-list-option {
  height: auto !important;
  padding-bottom: 0.68em !important;
  padding-left: 0.2em !important;
  padding-top: 0.68em !important;
}
